import React from "react"

import Layout from "@components/layout"
import OffSiteLink from '@components/off-site-link'
import SEO from "@components/seo"
import nickImage from '@images/nick.jpg'
import robinImage from '@images/robin.jpg'
import fitzImage from '@images/fitz.jpg'
import heloImage from '@images/helo.jpg'

const AboutPage = () => (
  <Layout bodyClassName='about'>
    <SEO 
      title="About"
      description="Our simple idea is to use an app to teach anyone how to be a great dog parent in just a few minutes a day." 
    />

    <section className="stripe stripe--hero stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h1 className="pageTitle">A brand new way to train and bond with your best friend</h1>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">Why Harper?</h2>
          <h3 className="listTitle">Dogs are amazing for us</h3>
          <p className="prose">Dogs are essential parts of the lives of millions of people around the world. As humans and dogs living under the same roof, we&rsquo;re taking part in a tradition that is <OffSiteLink href="https://en.wikipedia.org/wiki/Origin_of_the_domestic_dog">15,000 years old</OffSiteLink>. There&rsquo;s even science showing we&rsquo;ve evolved to benefit physically and mentally from being together.</p>
          <h3 className="listTitle">Training is love</h3>
          <p className="prose">The world today looks way different compared to the environment in which we evolved. It&rsquo;s especially true for dogs. Today, to care about dogs is to equip them with the skills to feel safe and confident in the modern environment as they navigate the day alongside you.</p>
          <h3 className="listTitle">To teach is to learn twice</h3>
          <p className="prose">Building a dog training habit will help create a calmer house and a more pleasant pup. But we were shocked at the lessons we learned through dog training. Watch as thoughtful dog training teaches you principles that can make you a better partner, family member, teammate, leader, and person.</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered stripe--alt">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">How Harper is different</h2>
          <p className="prose">Training, of any kind, is a lot like sweeping the floor&mdash;doing it well once won&rsquo;t guarantee that it will be clean forever. It&rsquo;s true of exercise, learning a language, or mastering a skill. And it&rsquo;s true of dog training.</p>
          <p className="prose">That&rsquo;s our secret: We focus <em>first</em> on helping you enjoy and build a habit of playing training games with your dog. Over the long run, it&rsquo;s the most effective way to train.</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered stripe--alt" id="difference-evidence">
      <div className="harper-venn"></div>
    </section>

    <section className="stripe stripe--centered stripe--reversed">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">How Harper makes money</h2>
          <p className="prose">Harper relies on subscriptions to keep the lights on, because it&rsquo;s important to us that our success is tightly bound to yours. Anything valuable is a product of repetition, so we want to create an incentive to grow and improve together, month after month.</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered stripe--alt">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">What Harper believes</h2>
          <h3 className="listTitle">Build for winter</h3>
          <p className="prose">You can only be helpful if you&rsquo;re still around. We&rsquo;re building Harper for the long haul. If we want to be around a long time, that means we have to prioritize integrity, humility, and putting skin in the game.</p>
          <h3 className="listTitle">Pull the thread through</h3>
          <p className="prose">Context matters. <OffSiteLink href="https://smile.amazon.com/Start-Why-Leaders-Inspire-Everyone/dp/1591846447/ref=smi_www_rco2_go_smi_5171374337?_encoding=UTF8&%2AVersion%2A=1&%2Aentries%2A=0&ie=UTF8">Starting with Why</OffSiteLink> connects decisions to purpose and embues them with meaning. Reminding people of the <OffSiteLink href="https://fs.blog/2016/04/second-order-thinking/">second-order benefits</OffSiteLink> of their choices makes it easier to keep the habit up.</p>
          <h3 className="listTitle">Always be pruning</h3>
          <p className="prose">Focus is a super power. We want do fewer things better. And we want to create a training studio that helps you excel at the few things that matter the most to you.</p>
          <h3 className="listTitle">Care and connect</h3>
          <p className="prose">We&rsquo;re a company founded by designers and engineers. We&rsquo;re craftsmen at heart. We believe that accepting and embracing our humanity is the most effective way to create products that matter.</p>
          <h3 className="listTitle">Smallest loop possible</h3>
          <p className="prose">We learn quickly. We respond quickly. We take action. And we find ways to create compounding effects in everything we do.</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered">
      <div className="container">
        <div className="copy">
          <h2 className="sectionTitle">Who Harper is</h2>
          <p className="prose">We&rsquo;re busy dog parents building something we desperately need for ourselves. We hope you find it useful too.</p>
        </div>
        <div className="roster">
          <div className="teammate robin">
            <img className="" src={robinImage} alt="Portrait of Harper co-founder Robin Daugherty" />
            <h3 className="listTitle">Robin</h3>
            <p>Engineering</p>
          </div>
          <div className="teammate helo">
            <img className="" src={heloImage} alt="Portrait of Harper dog Helo" />
            <h3 className="listTitle">Helo</h3>
            <p>Barking</p>
          </div>
          <div className="teammate nick" alt="Portrait of Harper co-founder Nick Cramer">
            <img className="" src={nickImage} />
            <h3 className="listTitle">Nick</h3>
            <p>Product</p>
          </div>
          <div className="teammate fitzgerald">
            <img className="" src={fitzImage} alt="Portrait of Harper dog Fitzgerald" />
            <h3 className="listTitle">Fitzgerald</h3>
            <p>Sleeping</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default AboutPage
